<template>
    <div class="main-container">
      <h1>EVENTOS</h1>
      <div class="events-container">

      </div>
      <div class="create-events-container">
        <div style="width: 50%; margin-bottom: 30px;">
          <div class="form-title">
            <p class="title-section">Titulo:</p>
            <InputTextOutline class="value-input" @_emit_method_order_input="_getValueFromInputValueTitle" input_label="Titulo" input_helper="Titulo" :has_focus="false" icon_class="value_icon_class" input_key_to_save_user_values="value_input_key_to_save_title_value" input_class_character_counter="value_input_class_character_counter" :permission="true" :icon_permission="false" :character_counter_permission="false" :helper_permission="false" :test_field_is_numeric="false" :length="128" computed_value="" :computed_value_permission="false" :is_input_textarea="false" />
          </div>
          <div class="form-date">
            <p class="title-section">Fecha:</p>
            <input type="date" id="fecha" name="fecha" pattern="\d{1,2}/\d{1,2}/\d{4}" required>
          </div>
          <div class="form-body">
            <p class="title-section">Descripcion:</p>
            <InputTextarea id="input_comment" @_emit_method_order_input_textarea="_getInputValueBody" ref="ref_body_input" input_key_to_textarea_values="key_body_input" input_placeholder="Body" />
          </div>
          <div class="form-tag" v-if="options_select_tag.length != 0">
            <p class="title-section">Tags:</p>
            <div style="display: flex; flex-direction: row; width: 100%;">
              <SelectOutline key="key_select_tag" ref="ref_select_tag" component_class="component_class_select_tag" label_name="Tags" :options="options_select_tag" default_value="" :helper_select_permission="true" @_emit_method_order_select="_getValueFromTagSelect" class="operaton-select-style" style="flex: 1; margin-right: 10px;"/>
              <div v-if="show_form_tag">
                <InputTextOutline class="value-input" @_emit_method_order_input="_getValueFromInputValueTag" input_label="Tag" input_helper="Tag" :has_focus="false" icon_class="value_icon_class" input_key_to_save_user_values="value_input_key_to_save_tag_value" input_class_character_counter="value_input_class_character_counter" :permission="true" :icon_permission="false" :character_counter_permission="false" :helper_permission="false" :test_field_is_numeric="false" :length="128" computed_value="" :computed_value_permission="false" :is_input_textarea="false" />
                <Button id="confirm-button" 
                @_emit_method_button="_createTag"  
                button_icon="button_icon" 
                button_name="✓" 
                button_key="button_key" 
                button_icon_id="button_icon_id" />
                <Button id="cancel-button" 
                @_emit_method_button="_showFormTag"  
                button_icon="button_icon" 
                button_name="X" 
                button_key="button_key" 
                button_icon_id="button_icon_id" />
              </div>
              <div v-else>
                <Button id="tag-button" 
                @_emit_method_button="_showFormTag"  
                button_icon="button_icon" 
                button_name="Crear tag" 
                button_key="button_key" 
                button_icon_id="button_icon_id" />
              </div>
            </div>
            <div class="tag-container">
              <div v-for="(item, index) in tags" :key="index">
                <div class="tag">
                  <span></span>
                  <span>{{ item }}</span>
                  <span class="close" @click="_removeTag(index)">X</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-button">
            <Button id="event-button" 
              @_emit_method_button="_postEvent"  
              button_icon="button_icon" 
              button_name="Crear Evento" 
              button_key="button_key" 
              button_icon_id="button_icon_id" />
          </div>
        </div>
        <div style="width: 45%;">
          <table border="1" style="width:100%;">
            <thead>
                <tr>
                    <th>id</th>
                    <th>fecha</th>
                    <th>titulo</th>
                    <th>descripcion</th>
                    <th>tags</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(evento, index) in events" :key="evento.hv_id">
                <td v-if="index !== editingIndex">{{ evento.hv_id }}</td>
                <td v-else>
                    <input id="edit-hv-id" v-model="editing_hv_id" />
                </td>
                <td v-if="index !== editingIndex">{{ _convertirTimestamp(evento.timestamp) }}</td>
                <td v-else>
                    <input type="date" id="edit-fecha" name="fecha" pattern="\d{1,2}/\d{1,2}/\d{4}" required v-model="editing_timestamp">
                </td>
                <td v-if="index !== editingIndex">{{ evento.title }}</td>
                <td v-else>
                    <input id="edit-title" v-model="editing_title" />
                </td>
                <td v-if="index !== editingIndex">{{ evento.description }}</td>
                <td v-else>
                    <input id="edit-description" v-model="editing_body" />
                </td>
                <td v-if="index !== editingIndex">{{ evento.tag }}</td>
                <td v-else>
                    <input id="edit-tag" v-model="editing_tags" />
                </td>
                <td style="text-align: center;">
                    <span v-if="index !== editingIndex" class="material-symbols-outlined" @click="_editRow(index)">edit</span>
                    <span v-else>
                        <span class="material-symbols-outlined" @click="_saveEdit()">save</span>
                        <span class="material-symbols-outlined" @click="_cancelEdit">cancel</span>
                    </span>
                    <span class="material-symbols-outlined" @click="_deleteEvent(evento.hv_id)">delete</span>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
    </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import InputTextOutline from "@/components/helpers/InputTextOutline.vue";
import InputTextarea from "@/components/helpers/InputTextarea.vue";
import SelectOutline from '@/components/helpers/SelectOutline.vue';
import Button from "@/components/helpers/Button.vue";
export default {
  mixins: [APICaller],
  name: 'Eventos',
  components: {
    InputTextOutline,
    InputTextarea,
    SelectOutline,
    Button,
  },
  data: () => ({
    options_select_tag: [],
    title: '',
    date: '',
    body: '',
    tags: [],
    events: [],
    show_form_tag: false,
    create_tag: "",
    editingIndex: null,
    editing_timestamp: "",
    editing_hv_id: "",
    editing_title: "",
    editing_body: "",
    editing_tags: "",
  }),

  mounted() {
    this._getEvents();
  },
  created: function() {

  },
  computed: {

  },
  methods: {
    _convertirTimestamp: function(timestamp) {
      const date = new Date(timestamp*1000);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },

    _getValueFromInputValueTitle: function(value){
      this.title = value[0];
    },
    _getValueFromInputValueTag: function(value){
      this.create_tag = value[0];
    },
    _getInputValueBody: function(value){
      this.body = value[0];
    },
    _getValueFromTagSelect: function(value){
      if(!this.tags.includes(value[0])){
        this.tags.push(value[0]);
      }
    },
    _removeTag: function(index){
      this.tags.splice(index, 1);
    },
    _postEvent: function(){
      var fechaInput = document.getElementById("fecha");
      const dateString = fechaInput.value;
      this.date = Date.parse(dateString);
      if(this.title == "" || this.body == "" || this.tags.length == 0 || this.date == ""){
        this.$vueOnToast.pop('error',"Todos los campos tienen que rellenarse.");
      }else{
        let params = new URLSearchParams();
        params.append('title', this.title);
        params.append('description', this.body);
        params.append('date', this.date);
        params.append('tags', this.tags);
        
        let success = () => {
          this.$vueOnToast.pop('success',"Evento creado");
          setTimeout(function(){ location.reload(); }, 500);
        }

        let url = "/api/v1/eurekers-historical-events/create";

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('534', 'Error creando evento.');

        this._postAPICall(url, params, successHandler, failureHandler);
      }
    },

    _getEvents: function(){
      this.options_select_tag = [];
      let success = (response) => {
        this.events = [];
        let result = response.data;
        let that = this;
        result.forEach(function(event) {
          if(event.title == 'tags'){
            that.options_select_tag.push({'name': event.tag, 'value': event.tag})
          }else{
            that.events.push(event);
          }
        });
      };

      let url = '/api/v1/eurekers-historical-events/get';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('535', 'Error recuperando eventos.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _showFormTag: function(){
      this.show_form_tag = !this.show_form_tag;
      this.create_tag = "";
    },

    _createTag: function(){
      let success = () => {
        this.$vueOnToast.pop('success',"Etiqueta creada");
        this._getEvents();
        this._showFormTag();
      };

      let url = '/api/v1/eurekers-historical-events/create-tag/' + this.create_tag;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('536', 'Error creando tag.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _deleteEvent: function(event_id){
      let success = () => {
        this.$vueOnToast.pop('success',"Evento eliminado");
        this._getEvents();
      };

      let url = '/api/v1/eurekers-historical-events/delete/' + event_id;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('537', 'Error eliminando evento.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _editRow(index) {
        this.editingIndex = index;
        this.editing_timestamp = this._convertirFechaParaInput(this._convertirTimestamp(this.events[index].timestamp));
        this.editing_hv_id = this.events[index].hv_id;
        this.editing_title = this.events[index].title;
        this.editing_body = this.events[index].description;
        this.editing_tags = this.events[index].tag;
    },
    _cancelEdit() {
        this.editingIndex = null;
    },
    _saveEdit() {
        const dateString = this.editing_timestamp;
        this.editing_timestamp = Date.parse(dateString);
        if(this.editing_title == "" || this.editing_body == "" || this.editing_tags.length == 0 || this.editing_timestamp == ""){
          this.$vueOnToast.pop('error',"Todos los campos tienen que rellenarse.");
        }else{
          let params = new URLSearchParams();
          params.append("event_id", this.editing_hv_id);
          params.append('title', this.editing_title);
          params.append('description', this.editing_body);
          params.append('date', this.editing_timestamp);
          params.append('tags', this.editing_tags);
          
          let success = () => {
            this.$vueOnToast.pop('success',"Evento editado");
            this.editingIndex = null;
            this._getEvents();
          }

          let url = "/api/v1/eurekers-historical-events/edit";

          const successHandler = new APICaller.SuccessHandler(success);
          const failureHandler = new APICaller.FailureHandler('538', 'Error editando evento.');

          this._postAPICall(url, params, successHandler, failureHandler);
        }
    },

    _convertirFechaParaInput: function(fecha) {
        const partes = fecha.split('/');
        const fechaISO = `${partes[2]}-${partes[1].padStart(2, '0')}-${partes[0].padStart(2, '0')}`;
        return fechaISO;
    },


  },
}

</script>

<style lang="scss" scoped>
  .form-title{
    padding: 10px 0 10px 0;
    background: white;
    height: 90%;
    align-self: center;
  }
  .title-section{
      font-size: 20px;
      font-weight: bold;
  }

  .create-events-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-body{
    padding: 10px 0 10px 0;
    background: white;
    height: 90%;
    align-self: center;
  }
  .form-date{
    padding: 10px 0 10px 0;
    background: white;
    height: 90%;
    align-self: center;
  }
  .form-tag{
    padding: 10px 0 10px 0;
    background: white;
    height: 90%;
    align-self: center;
  }
  .tag{
    border: 1px solid black;
    text-align: center;
    font-size: 12px;
    border-radius: 50px;
    width: 200px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;
  }
  .tag-container{
    display: flex;
    flex-direction: row;
  }
  .close{
    margin-right: 15px;
  }
  .close:hover{
    cursor: pointer;
  }
  #edit-hv-id{
    width: 50px;
  }
</style>